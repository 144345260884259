<template>
  <div class="card">
    <div class="card-body" v-if="!toggle">
      <span class="card-text">
        <span
          class="mx-1 pointer float-right"
          @click="toggle = !toggle">
          <fa icon="pencil-alt" class="text-warning"></fa>
        </span>
        <div class="">
          {{item.name}}
        </div>

        <div
          class="mt-2"
          v-if="contactIds.length > 0">
          <small>
            รหัสพนักงาน (Regex):
            <ol class="my-0">
              <li
                v-for="(child) in contactIds"
                :key="child.id">
                <div class="text-warning">{{child.name}}: {{child.regexExp}}</div>
              </li>
            </ol>
          </small>
        </div>
      </span>
    </div>

    <div class="card-body" v-if="toggle">
      <div class="card-text">
        <div class="form-row">
          <sgv-input-text
            label="ชื่อ"
            class="col-12"
            v-model="formData.name"
            :validations="[
              {text: 'required!', value: $v.formData.name.$dirty && !$v.formData.name.required}
            ]">
          </sgv-input-text>
        </div>

        <template v-if="item.id">
          <ul class="nav nav-pills justify-content-center mb-3">
            <li
              class="nav-item"
              v-for="tab in tabs"
              :key="tab.value"
              @click.prevent="selectedTab = tab.value">
              <a
                class="nav-link"
                href="#"
                :class="{'active': selectedTab === tab.value}">
                {{tab.text}}
              </a>
            </li>
          </ul>

          <div v-if="selectedTab === 'contactId'">
            <div class="form-row">
              <sgv-input-text
                label="รายละเอียด"
                class="col-6"
                placeholder="ฝ่ายผลิต..."
                v-model="contactIdFormData.name"
                :validations="[
                  {text: 'required!', value: $v.contactIdFormData.name.$dirty && !$v.contactIdFormData.name.required}
                ]">
              </sgv-input-text>

              <sgv-input-text
                label="รหัสพนักงาน (Regex)"
                class="col-6"
                placeholder="USR-01..."
                v-model="contactIdFormData.regexExp"
                :validations="[
                  {text: 'required!', value: $v.contactIdFormData.regexExp.$dirty && !$v.contactIdFormData.regexExp.required}
                ]">
              </sgv-input-text>
            </div>

            <button
              type="button"
              class="btn btn-success mb-3"
              @click="addContactId">
              เพิ่ม
            </button>

            <div
              v-if="contactIds.length > 0"
              class="mb-3">
              <small>
                <ol class="my-0">
                  <li
                    v-for="child in contactIds"
                    :key="child.id">
                    <span
                      @click="destroyData(child.id, item.id)"
                      class="pointer mr-1">
                      <fa icon="trash" class="text-danger"></fa>
                    </span>
                    <span class="">{{child.name}}: {{child.regexExp}}</span>
                    <span>
                      <fa
                        icon="arrow-up"
                        class="text-primary pointer mx-1"
                        @click="moveData(child.id, -1)">
                      </fa>
                      <fa
                        icon="arrow-down"
                        class="text-primary pointer mx-1"
                        @click="moveData(child.id, 1)">
                      </fa>
                    </span>
                  </li>
                </ol>
              </small>
            </div>
          </div>
        </template>

        <button
          v-if="isForm"
          type="button"
          class="btn btn-success"
          @click="updateData">
          เพิ่ม
        </button>

        <button
          v-if="!isForm"
          type="button"
          class="btn btn-warning"
          @click="updateData">
          แก้ไข
        </button>

        <button
          v-if="toggle"
          type="button"
          class="btn btn-primary ml-2"
          @click="cancelData">
          ยกเลิก
        </button>

        <button
          v-if="!isForm"
          type="button"
          class="btn btn-danger ml-2 float-right"
          @click="destroyData(item.id)">
          ลบ
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'

export default {
  props: {
    docType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    item: {
      type: Object,
      required: true
    },
    isForm: {
      type: Boolean,
      required: false
    },
    type: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      toggle: false,
      formData: {
        type: this.type,
        name: ''
      },
      contactIdFormData: {
        type: 'contactId',
        parentId: this.item.id,
        name: '',
        regexExp: ''
      },
      tabs: [
        {text: 'รหัสพนักงาน', value: 'contactId'}
      ],
      selectedTab: 'contactId'
    }
  },
  validations: {
    formData: {
      name: {required}
    },
    contactIdFormData: {
      name: {required},
      regexExp: {required},
    }
  },
  computed: {
    contactIds () {
      return this.item.children.filter(v => v.type === 'contactId')
    }
  },
  methods: {
    serializeInput (formData) {
      return formData
    },
    serializeTransferItemInput (formData) {
      const obj = {
        ...formData
      }

      return obj
    },
    updateData () {
      this.$v.formData.$touch()
      if (this.$v.formData.$invalid) return

      this.$emit('update', {
        id: this.item.id,
        input: this.serializeInput(this.formData),
        cb: () => {
          this.toggle = false
          this.$v.formData.$reset()
        },
        isRefetch: true
      })
    },
    destroyData (id, parentId) {
      this.$emit('destroy', {id, parentId})
    },
    cancelData () {
      this.toggle = false
      this.$emit('cancel', true)
    },
    addContactId () {
      this.$v.contactIdFormData.$touch()
      if (this.$v.contactIdFormData.$invalid) return

      this.$emit('child', {
        input: this.serializeInput(this.contactIdFormData),
        cb: () => {
          this.toggle = true
          this.$v.contactIdFormData.$reset()
        }
      })
    },
    moveData (id, step) {
      this.$emit('move', {id, step})
    },
  },
  watch: {
    toggle (value) {
      if (value && !this.isForm) {
        this.formData.name = this.item.name
        this.$nextTick(() => {

        })
      } if (value && this.isForm) {
        this.$nextTick(() => {

        })
      }
    },
  },
  created () {
    if (this.isForm) {
      this.toggle = true
    }
  }
}
</script>

<style lang="css" scoped>
</style>
